import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/ownership.md"

export default function OwnershipPage() {
  return (
    <LegalLayout
      description="Everything you need to know about Ready Five account ownership."
      heading="Who owns a Ready Five account?"
      source={source}
      title="Account Ownership"
    />
  )
}
